@import '../scss/index.scss';
.content_layuot{
    display: grid;
    min-height: 100vh;
    grid-template-columns: 300px 1fr 300px;
    grid-template-rows: 100px 1fr 1fr;
    grid-template-areas: "header    header  header"
                         "main    main    main"
                         "main    main    main";

    header{
        background: $background-primary;
        grid-area: header;
    }

    aside{
        grid-area: paleta;
        background: rgb(214, 214, 214);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    main{
        grid-area: main;
    }

    // footer{
    //     grid-area: footer;
    // }
    
}
