.horario_completo{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .div_horario{
        width: 70%;
        height: 650px;
        box-shadow: 0px 0px 5px 5px grey;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    
        img{
            width: 100%;
        }
    
        .div_column{
            width: 100%;
            height: 100%;
            background-color: #5AB9C2;
            display: flex;
            flex-direction: row;
            justify-content: center;
    
            .column_hora{
                width: 10%;
    
                .div_title{
                    width: 100%;
                    height: 35px;
                    border-radius: 5px;
                    background-color: #DE4C4A;
                    margin-top: -15px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
    
                    p{
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        color: #ffffff;
                        font-size: 18px;
                        text-align: center;
                    }
                }
    
                input {
                    width: 95%;
                    height: 35px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    background-color: #CDE8F3;
                    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-size: 18px;
                    text-align: center;
                }
            }
    
            .column_days{
                width: 15%;
                margin-left: 2%;
    
                .div_title{
                    width: 100%;
                    height: 35px;
                    margin-top: -15px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    background-color: #DE4C4A;
    
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
    
                    p{
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        color: #ffffff;
                        font-size: 18px;
                        text-align: center;
                    }
                }
    
                input {
                    width: 95%;
                    height: 35px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    background-color: #FFF398;
    
                    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-size: 18px;
                    text-align: center;
                }
            }
        }
    
        .btn_descargar{
            width: 200px;
            margin-top: 100px;
            margin-bottom: 200px;
        }
    }

    .conten_btn{
        width: 200px;
        margin-top: 50px;
        margin-bottom: 50px;

        img{
            width: 100%;

            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

}